<template>
  <div>
    <div class="d-flex flex-row align-content-center align-items-center recurrence-container">
      <vs-checkbox
        v-model="recurrenceEnable"
        color="primary"
        @change="onCheck"
      >
      </vs-checkbox>
      <span class="ml-3 recurrence-title">
        {{ $t('views.bookings.index.recurrence.title') }}
      </span>
    </div>
    <div class="border-bottom-grey"></div>
    <b-row v-if="recurrenceEnable" class="padding-container pt-3">
      <b-col
        :cols="$store.getters['layout/getInnerWidth'] > 991 ? 4 : 12"
      >
        <div class="mb-2 subtitle-recurrence">
          {{ $t('recurring.repeat_booking_all') }} :
        </div>
        <div class="d-flex">
          <div class="col-2 pl-0">
            <d-text-field
              v-model="recurrence.durationCount"
              autocomplete="off"
              size="sm"
              type="number"
              min="1"
              class-name="background-light-blue-inputs"
              :disabled="!recurrenceEnable"
            />
          </div>
          <div class="col-md-4">
            <select
              v-model="recurrence.periodicityType"
              :disabled="!recurrenceEnable"
              size="sm"
              class="background-light-blue-inputs form-control"
              @change="daysOfTheWeekInValid = false"
            >
              <option value="day">
                {{ $t('recurring.periodicities_by_day') }}
              </option>
              <option value="week">
                {{ $t('recurring.periodicities_by_week') }}
              </option>
            </select>
          </div>
        </div>
        <template v-if="recurrence.periodicityType === 'week'">
          <div class="mb-2 mt-2 subtitle-recurrence">
            {{ $t('recurring.repeat_to') }}
          </div>
          <div class="d-flex">
            <days-of-the-week
              :booking="booking"
              :recurrence="recurrence"
              :enabled="recurrenceEnable"
              @on:day-selected="daysOfTheWeekInValid = false"
            />
          </div>
        </template>
        <div
          v-if="daysOfTheWeekInValid"
          class="mt-3 d-flex flex-row align-content-center align-items-center"
        >
          <div class="text-danger">
            {{ $t('views.bookings.index.recurrence.invalid-days-of-the-week') }}
          </div>
        </div>
        <div
          v-if="durationCountInValid"
          class="mt-3 d-flex flex-row align-content-center align-items-center"
        >
          <div class="text-danger">
            {{ $t('views.bookings.index.recurrence.invalid-duration-count') }}
          </div>
        </div>
      </b-col>
      <div
        v-if="$store.getters['layout/getInnerWidth'] > 991"
        class="split-layout__divider"
      >
        <div class="split-layout__rule"></div>
        <div class="split-layout__rule"></div>
      </div>
      <b-col
        :cols="$store.getters['layout/getInnerWidth'] > 991 ? 4 : 12"
        :class="$store.getters['layout/getInnerWidth'] > 991 ? '' : 'mt-2'"
        class="margin-auto"
      >
        <div class="d-flex flex-row align-content-center align-items-center">
          <d-radio-button
            :text="$t('recurring.reccurring_booking_end_at')"
            :row="{ singleSelection: true }"
            :disabled="!recurrenceEnable"
            class="mt-1"
            color="#435179"
            @on:radio-button-check="setSelectionEndAt"
          />
          <div class="col-8">
            <div
              v-if="selectionAt === false"
              class="has-calendar"
              @click="showCalendar"
            >
                <span class="fa fa-calendar form-control-feedback">
                </span>
              <d-text-field
                :value="formattedToDate"
                :disabled="!recurrenceEnable"
                :readonly="recurrenceEnable"
                class-name="background-light-blue-inputs"
                size="sm"
              />
              <d-calendar-modal
                identifier="booking_recurring_end_at"
                :show="showRecurringAtCalendar"
                :filterFromDate="dateToFilterFrom"
                @on:calendar-change="onToDateChanged"
              />
            </div>

            <div
              v-else
              class="has-calendar"
            >
              <span class="fa fa-calendar form-control-feedback"></span>
              <d-text-field
                :disabled="true"
                class-name="background-light-blue-inputs"
                size="sm"
              />
            </div>
          </div>
        </div>
        <div class="mt-3 d-flex flex-row align-content-center align-items-center">
          <d-radio-button
            :text="$t('recurring.reccurring_booking_end_after')"
            :disabled="!recurrenceEnable"
            :row="{ singleSelection: true }"
            color="#435179"
            class="mt-1"
            @on:radio-button-check="setSelectionAt"
          />
          <div class="col-2">
            <d-text-field
              v-model="recurrence.countLimit"
              v-if="selectionEndAt === false"
              :disabled="!recurrenceEnable"
              size="sm"
              min="1"
              type="number"
              class-name="centred-text background-light-blue-inputs"
            />
            <d-text-field
              v-else
              :disabled="true"
              class-name="background-light-blue-inputs"
              size="sm"
            />
          </div>
          <div v-if="recurrence.periodicityType === 'week'" class="subtitle-recurrence">
            {{ $t('recurring.reccurring_booking_end_week_bookings') }}
          </div>
          <div
            v-if="recurrence.periodicityType === 'day'"
            class="subtitle-recurrence"
          >
            {{ $t('recurring.reccurring_booking_end_day_bookings') }}
          </div>
        </div>
        <div v-if="selectionInValid" class="mt-3 d-flex flex-row align-content-center align-items-center">
          <div class="text-danger">
            {{ messageSelectionInvalid ? $t(messageSelectionInvalid) : '' }}
          </div>
        </div>
      </b-col>
      <div
        v-if="$store.getters['layout/getInnerWidth'] > 991"
        class="split-layout__divider"
      >
        <div class="split-layout__rule"></div>
        <div class="split-layout__rule"></div>
      </div>
      <b-col
        :cols="$store.getters['layout/getInnerWidth'] > 991 ? 4 : 12"
        :class="$store.getters['layout/getInnerWidth'] < 991 ? '' : 'col-4-divider'"
      >
        <div v-if="resumeContent">
          <div :class="$store.getters['layout/getInnerWidth'] > 991 ? 'pl-3' : 'mt-2'">
            <div class="recurrence-title">
              {{ $t('recurring.resume') }}
            </div>
            <div class="mt-2">
              <p class="subtitle-recurrence p-line-height">
                {{ resumeContent }}
              </p>
            </div>
          </div>
        </div>
        <div
          v-else
          class="mt-5 d-flex align-items-center align-content-center justify-content-center"
        >
          <p class="recurrence-no-content p-line-height">
            {{ $t('views.bookings.index.recurrence.no-resume') }}
          </p>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import DaysOfTheWeek from "@form/booking/create-or-update/recurrence/DaysOfTheWeek";
import BookingRecurrenceModel from "@/classes/doinsport/BookingRecurrenceModel";
import {getWeekDayFromIndex} from "@/utils/form";

export default {
  data: () => ({
    selectionEndAt: false,
    selectionAt: false,
    showRecurringAtCalendar: false,
    selection: null,
    selectionInValid: false,
    daysOfTheWeekInValid: false,
    durationCountInValid: null
  }),
  components: {
    DaysOfTheWeek
  },
  props: {
    booking: {
      type: Object,
      default: () => {}
    },
    recurrenceEnable: {
      type: Boolean,
      default: false
    },
    recurrenceValidator: {
      type: Boolean,
      default: false
    },
    recurrence: {
      type: Object,
      default: new BookingRecurrenceModel()
    }
  },
  computed: {
    dateToFilterFrom () {
      return this.$moment().add(-1, 'days');
    },
    resumeContent() {
      if (
        this.recurrence.durationCount !== null &&
        this.recurrence.durationCount !== '' &&
        this.recurrence.periodicityType === 'week' &&
        this.recurrence.daysOfTheWeek.length > 0 &&
        this.recurrence.countLimit !== null &&
        this.recurrence.countLimit !== ''
      ) {
        return this.$t('views.bookings.index.recurrence.this-booking-week-repeated')
          + ' '
          + this.recurrence.durationCount
          + ' '
          + this.$t('views.bookings.index.recurrence.week-s-on')
          + ' '
          + this.formatSelectedDays()
          + ' '
          + this.$t('views.bookings.index.recurrence.while')
          + ' '
          + this.recurrence.countLimit
          + ' '
          + this.$t('views.bookings.index.recurrence.week-s')
          + '.'
          ;
      }

      if (
        this.recurrence.durationCount !== null &&
        this.recurrence.durationCount !== '' &&
        this.recurrence.periodicityType === 'week' &&
        this.recurrence.daysOfTheWeek.length > 0 &&
        this.recurrence.toDate !== null &&
        this.recurrence.toDate !== ''
      ) {
        return this.$t('views.bookings.index.recurrence.this-booking-week-repeated')
          + ' '
          + this.recurrence.durationCount
          + ' '
          + this.$t('views.bookings.index.recurrence.week-s-on')
          + ' '
          + this.formatSelectedDays()
          + ' '
          + this.$t('views.bookings.index.recurrence.end-at-until')
          + ' '
          + this.$moment(this.recurrence.toDate).locale(this.$i18n.locale).format('LL').replace(' 00:00', '')
          + '.'
          ;
      }

      if (
        this.recurrence.durationCount !== null &&
        this.recurrence.durationCount !== '' &&
        this.recurrence.periodicityType === 'day' &&
        this.recurrence.countLimit !== null &&
        this.recurrence.countLimit !== ''
      ) {
        return this.$t('views.bookings.index.recurrence.this-booking-day-repeated')
          + ' '
          + this.recurrence.durationCount
          + ' '
          + this.$t('views.bookings.index.recurrence.day-s')
          + ' '
          + this.$t('views.bookings.index.recurrence.while')
          + ' '
          + this.recurrence.countLimit
          + ' '
          + this.$t('views.bookings.index.recurrence.day-s')
          + '.'
          ;
      }

      if (
        this.recurrence.durationCount !== null &&
        this.recurrence.durationCount !== '' &&
        this.recurrence.periodicityType === 'day' &&
        this.recurrence.toDate !== null &&
        this.recurrence.toDate !== ''
      ) {
        return this.$t('views.bookings.index.recurrence.this-booking-day-repeated')
          + ' '
          + this.recurrence.durationCount
          + ' '
          + this.$t('views.bookings.index.recurrence.day-s')
          + ' '
          + this.$t('views.bookings.index.recurrence.end-at-until')
          + ' '
          + this.$moment(this.recurrence.toDate).locale(this.$i18n.locale).format('LL').replace(' 00:00', '')
          + '.'
          ;
      }

      return null;
    },
    formattedToDate() {
      if (this.recurrence.toDate && this.recurrence.toDate !== '') {
        return this.$moment(this.recurrence.toDate).format('DD/MM/YYYY');
      }
    }
  },
  watch: {
    recurrenceValidator: function () {
      if (this.recurrence.periodicityType === 'day') {
        this.recurrence.daysOfTheWeek = null;
      } else {
        if (this.recurrence.daysOfTheWeek.length === 0) {
          this.daysOfTheWeekInValid = true;
        }
      }
      if (this.selectionEndAt) {
        this.recurrence.countLimit = null;
      } else {
        this.recurrence.toDate = null;
      }
      if (!this.selectionEndAt && !this.selectionAt) {
        this.selectionInValid = true;
        this.messageSelectionInvalid = 'views.bookings.index.recurrence.end-date-not-selected';
      }
      if (this.selectionEndAt && (this.recurrence.toDate === null || this.recurrence.toDate === '')) {
        this.selectionInValid = true;
        this.messageSelectionInvalid = 'views.bookings.index.recurrence.end-date-invalid';
      }
      if (this.selectionAt && (this.recurrence.countLimit === '' || this.recurrence.countLimit === null)) {
        this.selectionInValid = true;
        this.messageSelectionInvalid = 'views.bookings.index.recurrence.count-limit-invalid';
      }
      if (this.recurrence.durationCount === null || this.recurrence.durationCount === '') {
        this.durationCountInValid = true;
      }

      if (this.resumeContent !== null) {
        this.$emit('on:recurrence-validated', this.selectionEndAt);
      } else {
        this.$emit('on:recurrence-not-validated');
      }
    }
  },
  methods: {
    formatSelectedDays() {
      let days = '';
      for (const i in this.recurrence.daysOfTheWeek) {
        if (parseInt(i) === this.recurrence.daysOfTheWeek.length - 1 && this.recurrence.daysOfTheWeek.length > 1) {
          days += ' '
            + this.$t('views.bookings.index.recurrence.and')
            + ' '
            + this.$t(getWeekDayFromIndex(this.recurrence.daysOfTheWeek[i])).toLowerCase()
            + ' '
          ;
        } else {
          days += this.$t(getWeekDayFromIndex(this.recurrence.daysOfTheWeek[i])).toLowerCase();

          if (this.recurrence.daysOfTheWeek.length > 1 && parseInt(i) !== this.recurrence.daysOfTheWeek.length - 2 ) {
             days += ', ';
          }

        }
      }
      return days;
    },
    onToDateChanged(date) {
      this.selectionInValid = false;
      this.recurrence.toDate = this.$moment.utc(date).tz(this.$store.getters["auth/currentClub"].timezone).format('YYYY-MM-DDTHH:mm:ss');
    },
    setSelectionEndAt() {
      this.selectionAt = false;
      this.selectionEndAt = true;
      this.recurrence.countLimit = null;
      this.selectionInValid = false;
    },
    setSelectionAt() {
      this.selectionAt = true;
      this.selectionEndAt = false;
      this.recurrence.toDate = null;
      this.selectionInValid = false;
    },
    onCheck() {
      this.$emit('on:recurrence-check', this.recurrenceEnable);
    },
    showCalendar() {
      if (this.selectionEndAt) {
        this.showRecurringAtCalendar = !this.showRecurringAtCalendar;
      }
    }
  },
  created() {
    this.$vs.theme({
      primary: '#435179'
    })
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/bookings/_booking-recurrence.scss";
@import "@lazy/bookings/_booking-container.scss";

.has-calendar .form-control-feedback {
  height: 0.88rem;
}

.p-line-height {
  line-height: 32px;
}

.centred-text {
  text-align: center;
}

/deep/ .vs-checkbox--input:checked + .vs-checkbox .vs-icon {
  font-family: 'Material Icons';
}
</style>
