<template>
  <div>
    <d-body-top-bar
      :title="$route.params.id ? $t('views.bookings.index.update.default') : $t('views.bookings.index.new.default')"
    >
      <div slot="tree">
        <label
          @click="$router.push({name: 'planning'})"
          class="pointer inactive-tree link-area ml-1"
        >
          {{ $t('data.menu.planning.menu') }}
        </label> /
        <label class="bg-doinsport-primary ml-1">
          {{ $route.params.id ? $t('views.bookings.index.update.default') : $t('views.bookings.index.new.default') }}
        </label>
      </div>
      <div
        slot="icons"
      >
        <switch-booking-button
          v-if="!$store.getters['playgrounds/getLoader']"
          :booking-type="bookingType"
          :disabled-edit="switchDisabled"
          @on:switch="onBookingTypeSwitch"
        />
        <b-spinner
          v-else
          variant="blue-light-spinner"
          class="mr-5"
          type="grow"
        ></b-spinner>
      </div>
    </d-body-top-bar>
    <div
      v-if="isLoaded"
      class="container-fluid"
    >
      <b-card class="custom-card-padding">
        <booking-header
          :booking="booking"
        />
        <div class="border-bottom-grey"></div>
        <booking-container
          :items="items"
          :booking="booking"
          :slot-data="slotData"
          :discounts="discounts"
          :booking-type="bookingType"
          :selected-playground="playgoundSelected"
          :canceled-participants="canceledParticipants"
          :selected-playground-options="selectedPlaygroundOptions"
          :full-canceled-participants-details="fullCanceledParticipantsDetails"
          @on:block-price:update="onBookingTypeSwitch"
          @on:discount-icon:click="onDiscountIconClick"
          @on:undiscount-icon:click="onUnDiscountIconClick"
        />
        <b-row
          :class="innerWidth ? 'mr-3' : 'button-fixed'"
        >
          <b-col
            :align="innerWidth ? 'right' : 'center'"
            :class="innerWidth ? '' : 'p-0 '"
          >
            <d-button
              :class="[btnLoading ? 'disabled' : '', innerWidth ? '' : 'custom-mobile-button']"
              :icon="btnLoading ? 'fa fa-refresh fa-spin' : ''"
              icon-position="right"
              text="general.actions.save"
              class="d-btn-sm font-text-title d-btn btn d-btn-danger font-text-title"
              @on:button-click="onSave"
            />
          </b-col>
        </b-row>
      </b-card>
      <b-card
        v-if="mode === 'create'"
        class="custom-card-padding"
      >
        <recurrence
          :booking="booking"
          :recurrence="recurrence"
          :recurrence-enable="recurrenceEnable"
          :recurrence-validator="recurrenceValidator"
          @on:recurrence-check="onRecurrenceCheck"
          @on:recurrence-validated="onRecurrenceIsValidated"
          @on:recurrence-not-validated="onRecurrenceIsNotValidated"
        />
      </b-card>
      <booking-discount-modal
        :display="displayDiscountModal"
        :hide="hideDiscountModal"
        :discount="discount"
        @on:discount:save="onDiscountSave"
      />
    </div>
  </div>
</template>
<script>
import Booking from "@/classes/doinsport/Booking";
import {getPlaygroundList} from "@api/doinsport/services/playground/playground.api";
import BookingModel, {
  DEFAULT_BOOKING_TYPE, FORMULA_BOOKING_TYPE,
  LEISURE_BOOKING_TYPE,
  LESSON_BOOKING_TYPE
} from "@/classes/doinsport/BookingModel";
import {isNotUndefinedAndNotNull, refine} from "@/utils/classes";
import {
  addBooking,
  getBooking,
  getParticipantsByBooking,
  updateBooking
} from "@api/doinsport/services/bookings/booking.api";
import Participant from "@/classes/doinsport/Participant";
import {postParticipant, putParticipant} from "@api/doinsport/services/bookings/participant/participant.api";
import Recurrence from "@views/bookings/create-or-update/container/Recurrence";
import BookingRecurrenceModel from "@/classes/doinsport/BookingRecurrenceModel";
import {_delete, _get, _put} from "@api/doinsport/services/httpService";
import SwitchBookingButton from "@custom/booking/SwitchBookingButton";
import {FORMULA_TYPE, LEISURE_TYPE, LESSON_TYPE, SPORT_TYPE} from "@/classes/doinsport/Price";
import BookingDiscountModal from "@custom/booking/BookingDiscountModal";
import Discount, {AMOUNT_TYPE} from "@/classes/doinsport/Discount";
import OptionDiscount from "@/classes/doinsport/OptionDiscount";
import ParticipantDiscount from "@/classes/doinsport/ParticipantDiscount";
import BookingDiscount from "@/classes/doinsport/BookingDiscount";
import {postDiscount} from "@api/doinsport/services/bookings/discount.api";
import {toFloatFixed, toIntFixed} from "@/utils/string";
import {toPrice} from "@/utils/currency";

export default {
  data: () => ({
    booking: new Booking(),
    discount: null,
    discounts: [],
    selectedPlaygroundOptions: [],
    playgroundOptions: [],
    participantsAlreadyCanceled: [],
    serializedBooking: null,
    items: [],
    canceledParticipants: [],
    fullCanceledParticipantsDetails: [],
    participants: [],
    recurrenceValidator: false,
    isLoaded: false,
    recurrence: new BookingRecurrenceModel(),
    recurrenceEnable: false,
    btnLoading: false,
    switchDisabled: true,
    firstDeep: false,
    displayDiscountModal: false,
    hideDiscountModal: false,
    modalId: 'ModalId',
    retry: 0,
    storedItem: null,
    retryActivities: [],
    requestHandler: [],
    bookingType: 'default',
    loadedActivities: [],
  }),
  components: {
    BookingDiscountModal,
    SwitchBookingButton,
    Recurrence,
    BookingHeader: () => import('./Header'),
    BookingContainer: () => import('./container/Index'),
  },
  watch: {
    blockPrices: {
      deep: true,
      handler() {
        if (this.playgoundSelected.bookingType === 'multiple') {
          this.enableSwitch();
        }
      }
    }
  },
  props: {
    slotData: {
      type: Object,
      default: () => {
      }
    },
    playgoundSelected: {
      type: Object,
      default: () => {
      }
    },
    selectedActivity: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    innerWidth() {
      return this.$store.getters['layout/getInnerWidth'] > 700;
    },
    blockPrices() {
      return this.$store.getters["playgrounds/getSelectedBlockPrices"];
    },
    playgrounds() {
      return this.$store.getters["playgrounds/getPlaygrounds"];
    },
    mode() {
      return this.$route.name === 'booking_new_index' ? 'create' : 'update';
    }
  },
  methods: {
    onDiscountIconClick(item) {
      this.storedItem = item;
      this.discount = new Discount().getDiscountModel(item);

      this.$nextTick(() => {
        this.displayDiscountModal = !this.displayDiscountModal;
      });
    },
    onUnDiscountIconClick(item) {
      if (isNotUndefinedAndNotNull(this.$route.params.id)) {
        this.UnDiscountBookingPropsOnEdit(item);
      } else {
        this.recentUnDiscount(item);
        this.$bus.$emit('on:refresh-discounts');
      }
    },
    onDiscountSave() {
      if (isNotUndefinedAndNotNull(this.$route.params.id)) {
        this.discountBookingPropsOnEdit(this.discount);
      } else {
        this.updateDiscounts(this.discount);
        this.$bus.$emit('on:refresh-discounts');
      }
    },
    onBookingTypeSwitch(type) {
      this.bookingType = type;
    },
    recentUnDiscount(item) {
      const findDiscount = this.discounts.find(el => el.id === item.id);

      if ("undefined" !== typeof findDiscount) {
        this.discounts.splice(this.discounts.indexOf(findDiscount), 1);
      }
    },
    updateDiscounts(discount) {
      const findDiscount = this.discounts.find(el => el.id === discount.id);

      if ("undefined" === typeof findDiscount) {
        this.discounts.push(discount);
      } else {
        this.discounts.splice(this.discounts.indexOf(findDiscount), 1);
        this.discounts.push(discount);
      }
    },
    discountBookingPropsOnEdit(item) {

      if ("undefined" !== typeof item.bookingParticipant) {
        let findParticipantFromDiscount = this.items.find(el => el.id === this.discount.id);

        if ("undefined" !== typeof findParticipantFromDiscount) {
          if ("undefined" !== typeof findParticipantFromDiscount.iriParticipant) {
            const bookingParticipantDiscount =
              {
                bookingParticipant: findParticipantFromDiscount.iriParticipant,
                amount: this.discount.amountType === AMOUNT_TYPE ? toIntFixed(this.discount.amount) : parseInt(toPrice(this.discount.amount)),
                amountType: this.discount.amountType,
              }

            postDiscount(bookingParticipantDiscount)
              .then((response) => {
                this.discounts.push(new ParticipantDiscount().createParticipantDiscountCell(response, findParticipantFromDiscount));
              })
              .finally(() => {
                this.$bus.$emit('on:refresh-discounts');
              })
            ;
          } else {
            this.updateDiscounts(this.discount);
            this.$bus.$emit('on:refresh-discounts');
          }
        }
      }

      if ("undefined" !== typeof item.bookingPlaygroundOption) {
        const findExistentPlaygroundOption = this.playgroundOptions.find(el => el.option['@id'] === item.id);
        if ("undefined" !== typeof findExistentPlaygroundOption) {
          const bookingOptionDiscount =
            {
              bookingPlaygroundOption: findExistentPlaygroundOption['@id'],
              amount: this.discount.amountType === AMOUNT_TYPE ? toIntFixed(this.discount.amount) : parseInt(toPrice(this.discount.amount)),
              amountType: this.discount.amountType,
            }
          postDiscount(bookingOptionDiscount)
            .then((response) => {
              this.discounts.push(new OptionDiscount().createOptionDiscountCell(response, findExistentPlaygroundOption));
            })
            .finally(() => {
              this.$bus.$emit('on:refresh-discounts');
            })
          ;
        } else {
          this.updateDiscounts(item);
          this.$bus.$emit('on:refresh-discounts');
        }
      }


      if ("undefined" !== typeof item.booking) {
        this.discounts.push(item);
        this.$bus.$emit('on:refresh-discounts');
      }
    },
    UnDiscountBookingPropsOnEdit(item) {
      if ("undefined" !== typeof item.discount.bookingParticipant) {
        if (isNotUndefinedAndNotNull(item.iriParticipant)) {
          _delete(item.discount.id)
            .then(() => {
              this.recentUnDiscount(item.discount);
            })
            .finally(() => {
              this.$bus.$emit('on:refresh-discounts');
            })
          ;
        } else {
          this.recentUnDiscount(item);
          this.$bus.$emit('on:refresh-discounts');
        }
      }
    },
    onRecurrenceCheck(check) {
      this.recurrenceEnable = check;
    },
    onSave() {
      if (this.btnLoading === false) {
        this.btnLoading = true;
        this.booking.playgroundOptions = [];
        this.booking.playgroundOptionsTmp.forEach(option => {
          const alreadyExist = this.selectedPlaygroundOptions.find(el => el.option['@id'] === option.option);

          if (isNotUndefinedAndNotNull(alreadyExist)) {
            if (option.quantity === alreadyExist.quantity) {
              this.booking.playgroundOptions.push(alreadyExist[`@id`])
            } else {
              this.booking.playgroundOptions.push({
                id: alreadyExist[`@id`],
                quantity: option.quantity
              })
            }
          } else {
            this.booking.playgroundOptions.push(option)
          }
        });

        const serializedBooking = new BookingModel(this.booking);
        const participants = [];

        this.formatBookingModel(serializedBooking);
        serializedBooking.club = this.$store.getters["auth/currentClub"]['@id'];
        serializedBooking.userClub = this.$store.getters["auth/currentUser"]['@id'];
        serializedBooking.creationOrigin = 'administration';
        serializedBooking.paymentMethod = 'on_the_spot';

        serializedBooking.startAt = this.formatBookingDate(this.createBookingDate(this.booking.startAt));
        serializedBooking.endAt = this.formatBookingDate(this.createBookingDate(this.booking.endAt));

        if (this.$route.params.id) {
          delete serializedBooking.creationOrigin;
          delete serializedBooking.paymentMethod;
          delete serializedBooking.participants;
        } else {
          for (const item of this.items) {
            const participant =
              {
                client: isNotUndefinedAndNotNull(item.participant) ? item.participant.iri : null,
                subscriptionCard: item.subscriptionCardUsed ? item.subscriptionCardUsed['@id'] : null,
                category: item.category,
                bookingOwner: item.bookingOwner
              };
            participants.push(participant);
          }

          serializedBooking.participants = participants;
        }
        if (this.bookingHasParticipants(serializedBooking) && this.validateCategories() && this.formulaHasAtLeastOneCustomer(serializedBooking)) {
          if (this.recurrenceEnable) {
            this.validateRecurrence();
            this.serializedBooking = serializedBooking;
          } else {
            serializedBooking.recurrence = null;

            this.proceedCreateOrUpdateBooking(serializedBooking);
          }
        } else {
          this.btnLoading = false;
          if (!this.validateCategories()) {
            this.$flash(null, this.$t('booking_participant_category_selection'));
          } else if (!this.isValidBookingName(serializedBooking)) {
            this.$flash(null, this.$t('booking_name'));
            this.$bus.$emit('on:empty-booking-name');
          } else {
            this.$flash(null, this.$t('booking_participant_selection'));
          }
        }
      }
    },
    createBookingDate(date) {
      const timezone = this.$store.getters["auth/currentClub"].timezone;

      const hour = this.$moment(date).format('HH');
      const minute = this.$moment(date).format('mm');

      const dateFormat = this.$moment.utc(date).tz(timezone);

      dateFormat.set({
        hour: hour,
        minute: minute,
        second: 0,
        millisecond: 0
      });

      dateFormat.toISOString();
      dateFormat.format();

      return dateFormat;
    },
    onRecurrenceIsNotValidated() {
      this.btnLoading = false;
    },
    onRecurrenceIsValidated(selection) {
      const serializedRecurrence = new BookingRecurrenceModel(this.recurrence);

      serializedRecurrence.durationCount = parseInt(serializedRecurrence.durationCount);
      if (!selection) {
        serializedRecurrence.countLimit = parseInt(serializedRecurrence.countLimit);
      }

      if (this.serializedBooking) {
        this.serializedBooking.recurrence = serializedRecurrence;
        this.proceedCreateOrUpdateBooking(this.serializedBooking);
      }
    },
    proceedCreateOrUpdateBooking(serializedBooking) {
      this.createOrUpdateBooking(serializedBooking)
        .then((response) => {
          if (isNotUndefinedAndNotNull(this.$route.params.id)) {
            this.updateBookingParticipants(response);
          } else {
            const bookingResponse = response.data;

            getParticipantsByBooking(bookingResponse.id)
              .then((response) => {
                this.participants = response.data['hydra:member'];
                this.setDiscountsOnBookingCreated(bookingResponse);
              })
            ;
          }
          this.$store.commit('planning/addOperation', response.data);
        })
        .catch((er) => {
          this.btnLoading = false;
        })
        .finally(() => {
          this.$store.dispatch('planning/setDate', this.$moment(this.booking.startAt).format('YYYY-MM-DD'));
        })
      ;
    },
    formatBookingModel(booking) {
      const blockPrice = this.blockPrices.find(el => el['@id'] === booking.timetableBlockPrice);

      if (blockPrice.activityType !== LESSON_BOOKING_TYPE) {
        delete booking.registrationAvailableOnline;
        delete booking.maxParticipantsCountLimit;
        delete booking.registrationTimeBeforeStart;
        delete booking.coaches;
        if (blockPrice.activityType !== FORMULA_BOOKING_TYPE) {
          delete booking.fixedParticipantsCount;
          delete booking.client;
        } else {
          if (this.items.length > 0) {
            this.items.forEach(item => {
              if (item.participant) {
                booking.client = item.participant.code;
              }
            });
          }
        }
      } else {
        booking.maxParticipantsCountLimit = parseInt(booking.maxParticipantsCountLimit);
        booking.registrationTimeBeforeStart = booking.registrationTimeBeforeStart * 3600;

        if (booking.coaches.length > 0) {
          const coaches = [];

          for (const coach of booking.coaches) {
            coaches.push(coach.code);
          }

          booking.coaches = coaches;
        }
      }
    },
    isNotAVisitor(item) {
      return (!(item.client === null && item.user === null));
    },
    async updateBookingParticipants(response) {

      //Cancel all removed participants

      const requests = [];

      for (const participantToDelete of this.canceledParticipants) {
        const findInItems = this.items.find(el => el.iriParticipant === participantToDelete);
        if ("undefined" === typeof findInItems) {
          requests.push(await putParticipant(participantToDelete.replace('/clubs/bookings/participants/', ''), {canceled: true}));
        }
      }

      // Post new customers and update customers already canceled

      for (const item of this.items) {

        const data = {
          client: isNotUndefinedAndNotNull(item.participant) ? item.participant.iri : null,
          subscriptionCard: item.subscriptionCardUsed ? item.subscriptionCardUsed['@id'] : null,
          category: item.category,
          bookingOwner: item.bookingOwner
        };

        if (item.iriParticipant) {
          requests.push(await putParticipant(item.iriParticipant.replace('/clubs/bookings/participants/', ''), data));
        } else {
          let findExistentParticipants = null;

          if (item.participant) {
            if (item.participant.item) {
              findExistentParticipants = this.participantsAlreadyCanceled.find(el => el.client['@id'] === item.participant.item['@id']);
            }
          } else {
            requests.push(await postParticipant({
              booking: response.data['@id'],
              client: null,
              category: item.category,
              bookingOwner: item.bookingOwner
            }));
            continue;
          }

          if (isNotUndefinedAndNotNull(findExistentParticipants)) {
            requests.push(await _put(findExistentParticipants['@id'], {canceled: false}));
          } else {
            const participantDetails = this.fullCanceledParticipantsDetails.find(el => el.id === item.id);
            if ("undefined" === typeof participantDetails) {
              data.booking = response.data['@id'];
              requests.push(await postParticipant(data));
            } else {
              requests.push(await _put(participantDetails.iriParticipant, {canceled: false}));
            }
          }
        }
      }

      Promise.all(requests)
        .then(results => {
          _get(response.data['@id'])
            .then((resp) => {
              this.setDiscountsOnBookingCreated(resp.data);
            })
          ;
        })
      ;
    },

    validateRecurrence() {
      this.recurrenceValidator = !this.recurrenceValidator;
    },
    validateCategories() {
      const blockPrice = this.blockPrices.find(el => el['@id'] === this.booking.timetableBlockPrice);

      if (blockPrice.activityType === LEISURE_TYPE) {
        for (const item of this.items) {
          if (null === item.category) return false;
        }
      }

      return true;
    },
    formulaHasAtLeastOneCustomer(booking) {
      const blockPrice = this.blockPrices.find(el => el['@id'] === booking.timetableBlockPrice);
      if (blockPrice.activityType === FORMULA_BOOKING_TYPE) {
        let onlyVisitors = false;

        for (const participantItem of this.items) {
          if (participantItem.participant) {
            onlyVisitors = true;
            break;
          }
        }

        return onlyVisitors;
      }

      return true;
    },
    bookingHasParticipants(booking) {
      const blockPrice = this.blockPrices.find(el => el['@id'] === booking.timetableBlockPrice);

      if ((this.items.length === 0)) {
        if (booking.name === null || booking.name.trim().length === 0) {
          if (blockPrice.activityType !== LESSON_BOOKING_TYPE) {
            booking.name = this.$store.getters["auth/currentClub"].name;
          }
        }
        if (blockPrice.activityType === LEISURE_BOOKING_TYPE) {
          return false;
        }
      } else {
        let onlyCustomers = false;

        for (const participantItem of this.items) {
          if (participantItem.participant) {
            onlyCustomers = true;
            break;
          }
        }

        if (!onlyCustomers) {
          if (blockPrice.activityType !== LESSON_BOOKING_TYPE) {
            if (booking.nameManuallyUpdated === false) {
              booking.name = this.$store.getters["auth/currentClub"].name;
            }
          }
        }
      }

      if (!this.isValidBookingName(booking)) {
        return false;
      }

      return true;
    },
    isValidBookingName(booking) {
      if (booking.name === null) {

        return false;
      } else {
        if (booking.name.trim() === '') {

          return false;
        }
      }

      return true;
    },
    createOrUpdateBooking(booking) {
      return (this.$route.params.id) ? updateBooking(this.booking.id, booking) : addBooking(booking);
    },
    formatBookingDate(date) {
      return this.$moment
        .utc(date)
        .tz(this.$store.getters["auth/currentClub"].timezone)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
        ;
    },
    avatar(participant) {
      if (null !== participant.client) {
        if (participant.client.identityPhoto) {
          return participant.client.identityPhoto;
        }
      }

      if (null !== participant.user) {
        if (participant.user.avatar) {
          return participant.user.avatar;
        }
      }

      return null;
    },

    refineParticipant(object, iri) {
      delete object.id;
      delete object.initialPrice;

      if (object.amountType === AMOUNT_TYPE) {
        object.amount = parseInt(parseFloat(object.amount).toFixed(2) * 100);
      } else {
        object.amount = parseInt(parseFloat(object.amount).toFixed(2));
      }

      object.bookingParticipant = iri;

      return object;
    },

    refineOption(object, iri) {
      delete object.id;

      delete object.initialPrice;
      if (object.amountType === AMOUNT_TYPE) {
        object.amount = parseInt(parseFloat(object.amount).toFixed(2) * 100);
      } else {
        object.amount = parseInt(parseFloat(object.amount).toFixed(2));
      }

      object.bookingPlaygroundOption = iri;

      return object;
    },

    refineBooking(object, iri) {
      delete object.id;
      delete object.initialPrice;

      if (object.amountType === AMOUNT_TYPE) {
        object.amount = parseInt(parseFloat(object.amount).toFixed(2) * 100);
      } else {
        object.amount = parseInt(parseFloat(object.amount).toFixed(2));
      }

      object.booking = iri;

      return object;
    },

    setDiscountsOnBookingCreated(booking) {
      const discountRequests = [];
      // set options discounts iri(s)

      for (const playgroundOption of booking.playgroundOptions) {
        let optionDiscount = this.discounts.find(el => el.id === playgroundOption.option['@id']);

        if ("undefined" !== typeof optionDiscount) {

          if (optionDiscount.bookingPlaygroundOption === null) {
            let formattedOption = JSON.parse(JSON.stringify(optionDiscount));
            formattedOption = this.refineOption(formattedOption, playgroundOption['@id']);

            discountRequests.push(formattedOption);
          }
        }
      }

      // set participants discounts iri(s)

      for (const participant of this.participants) {
        let customerIdFromParticipant = null;

        if (participant.client) {
          customerIdFromParticipant = participant.client.id;
        } else if (participant.user) {
          customerIdFromParticipant = participant.user.id;
        }

        if (customerIdFromParticipant) {
          let participantDiscount = this.discounts.find(el => el.id === customerIdFromParticipant);

          if ("undefined" !== typeof participantDiscount) {
            if (participantDiscount.bookingParticipant === null) {
              let formattedParticipant = JSON.parse(JSON.stringify(participantDiscount));

              formattedParticipant = this.refineParticipant(formattedParticipant, participant['@id']);
              discountRequests.push(formattedParticipant);
            }
          }
        }
      }

      // set booking discount

      let bookingDiscount = null;

      for (let discount of this.discounts) {
        if ("undefined" !== typeof discount.booking) {
          let formattedDiscount = JSON.parse(JSON.stringify(discount));
          bookingDiscount = this.refineBooking(formattedDiscount, booking['@id']);
        }
      }

      this.postRegularDiscounts(bookingDiscount, discountRequests);
    },
    async postRegularDiscounts(bookingDiscount, discountRequests) {
      const requestsDiscounts = [];

      for (const discount of discountRequests) {
        if ("undefined" === typeof discount.booking) {
          requestsDiscounts.push(await postDiscount(discount));
        }
      }
      Promise.allSettled(requestsDiscounts)
        .then((responses) => {
          if (bookingDiscount) {
            postDiscount(bookingDiscount)
              .then((response) => {
                this.btnLoading = false;
              })
            ;
          } else {
            this.btnLoading = false;
          }
        })
        .finally(() => {
          this.$router.push({name: 'planning'});
        })
      ;
    },
    initBooking() {
      if (this.$route.params.id) {
        this.playgroundOptions = [];
        let bookingDiscount = null;

        getBooking(this.$route.params.id)
          .then((response) => {
            this.booking = response.data;
            this.playgroundOptions = response.data.playgroundOptions;

            bookingDiscount = response.data.discount;

            if (!isNotUndefinedAndNotNull(this.playgoundSelected)) {
              const playgrounds = this.$store.getters["playgrounds/getPlaygrounds"];
              this.playgoundSelected = playgrounds.find(el => el['@id'] === this.booking.playgrounds[0]['@id']);
            }
            if (isNotUndefinedAndNotNull(this.selectedActivity)) {
              this.booking.activity = this.selectedActivity;
            }
            if (response.data.playgroundOptions) {
              this.selectedPlaygroundOptions = JSON.parse(JSON.stringify(response.data.playgroundOptions));
            } else {
              this.selectedPlaygroundOptions = [];
            }
            const coachesDetails = this.booking.coaches;
            refine(this.booking);
            this.booking.startAt = this.$moment.utc(this.booking.startAt).tz(this.$store.getters["auth/currentClub"].timezone);
            this.booking.endAt = this.$moment.utc(this.booking.endAt).tz(this.$store.getters["auth/currentClub"].timezone);
            if (this.booking.activityType === FORMULA_BOOKING_TYPE) {
              this.bookingType = FORMULA_BOOKING_TYPE;
            } else {
              this.bookingType = this.booking.activityType === LESSON_TYPE ? LESSON_BOOKING_TYPE : DEFAULT_BOOKING_TYPE;
            }

            if (this.bookingType === LESSON_TYPE) {
              this.booking.registrationTimeBeforeStart = this.booking.registrationTimeBeforeStart / 3600;
              if (this.booking.coaches.length > 0) {
                const coaches = [];

                for (const coach of coachesDetails) {
                  coaches.push({
                    label: coach.firstName + ' ' + coach.lastName,
                    code: coach['@id']
                  });
                }
                this.booking.coaches = coaches;
              }
            }
            this.loadParticipants(response.data, bookingDiscount)
          })
        ;
      } else {
        if (this.slotData === null || this.slotData === undefined) {
          this.$router.push({name: 'planning'});
        } else {
          this.booking.playgrounds = ['/clubs/playgrounds/' + this.playgoundSelected.id];
          this.booking.recurrence = null;
          const startAt = this.slotData.date;
          const endAt = this.slotData.date.clone();
          endAt.add(parseInt(this.slotData.step), 'minutes');
          this.booking.startAt = startAt;
          this.booking.endAt = endAt;
          this.isLoaded = true;
        }
      }
    },
    loadParticipants(booking, bookingDiscount) {
      this.items = [];
      this.participants = [];
      this.participantsAlreadyCanceled = [];

      getParticipantsByBooking(this.booking.id)
        .then((response) => {
          for (const customer of response.data['hydra:member']) {
            if (!customer.canceled) {
              this.participants.push(customer);

              if (customer.client) {
                const participant = new Participant(customer.client);
                participant.iriParticipant = customer['@id'];
                participant.discount = customer.discount;
                participant.price = customer.price;
                participant.restToPay = customer.restToPay;
                participant.canceled = customer.canceled;
                participant.phoneNumber = isNotUndefinedAndNotNull(customer.client) ? customer.client.phoneNumber : '-';
                participant.about = customer.client.fullName;
                participant.category = customer.category;
                participant.participant = {
                  label: customer.client.fullName,
                  code: customer.client['@id'],
                  item: customer.client,
                  iri: customer.client['@id']
                };
                if (customer.subscriptionCard) {
                  participant.participant.item.subscriptionCardsAvailable = [customer.subscriptionCard];
                  participant.participant.item.subscriptionCardsAvailable[0].subscriptionPlan = customer.subscriptionCard.plan;
                }

                participant.actions = ['delete'];
                participant.bookingOwner = customer.bookingOwner !== null ? customer.bookingOwner : false;
                participant.partcipantPreview = {
                  photo: this.avatar(customer),
                  values: customer.client
                }
                participant.subscriptionCardUsed = customer.subscriptionCard;
                participant.toPay = toFloatFixed(customer.price) + this.$currency;
                participant.participantSubscription = customer.subscriptionCard ? customer.subscriptionCard.name : '-';
                participant.type = 'participant';
                this.items.push(participant);
              } else {
                const participant = new Participant();
                participant.id = customer.id;
                participant.iriParticipant = customer['@id'];
                participant.discount = customer.discount;
                participant.toPay = toFloatFixed(this.booking.pricePerParticipant) + this.$currency;
                participant.about = this.$t('components.custom.planning.booking-details-modal.general-information.visitor');
                participant.participant = null;
                participant.canceled = customer.canceled;
                participant.actions = ['delete'];
                participant.phoneNumber = '-';
                participant.category = customer.category;
                participant.participantSubscription = '-'
                participant.partcipantPreview = {
                  photo: null,
                  values: {
                    firstName: 'I', lastName: 'N'
                  }
                }
                participant.type = 'visitor';
                this.items.push(participant);
              }
            } else {
              if (this.isNotAVisitor(customer)) {
                this.participantsAlreadyCanceled.push(customer);
              }
            }
          }
        })
        .finally(() => {
          this.loadDiscounts(booking, bookingDiscount);
          this.isLoaded = true;
        })
      ;
    },
    loadPlaygrounds() {
      getPlaygroundList()
        .then(resp => {
          if (resp) {
            this.$store.dispatch('playgrounds/savePlaygrounds', resp.data['hydra:member']);
          }
        })
        .finally(() => {
          this.$store.commit('multipleBooking/setPriceCategories', []);
          this.initBooking();
        })
      ;
    },
    loadDiscounts(booking, bookingDiscount) {
      this.discounts = [];

      for (const playgroundOption of this.playgroundOptions) {
        if (playgroundOption.discount) {
          this.discounts.push(new OptionDiscount().createDiscountCell(playgroundOption));
        }
      }
      if (bookingDiscount) {
        this.discounts.push(new BookingDiscount().createDiscountCell(booking, bookingDiscount));
      }

      for (const participant of this.participants) {
        if (participant.discount) {
          this.discounts.push(new ParticipantDiscount().createDiscountCell(participant));
        }
      }
    },
    enableSwitch() {
      let activityTypes = [];

      if (this.blockPrices.length > 0) {
        const selectedTimeTableBlockPrice = this.blockPrices.find(el => el ['@id'] === this.booking.timetableBlockPrice);
        activityTypes[0] = this.blockPrices[0].activityType;

        for (const blockPrice of this.blockPrices) {
          const checkInTypes = activityTypes.find(el => el === blockPrice.activityType);

          if ("undefined" === typeof checkInTypes) {
            activityTypes.push(blockPrice.activityType);
          }
        }

        const hasLessonType = isNotUndefinedAndNotNull(activityTypes.find(el => el === LESSON_TYPE));
        const hasSportType = isNotUndefinedAndNotNull(activityTypes.find(el => el === SPORT_TYPE));
        const hasLeisureType = isNotUndefinedAndNotNull(activityTypes.find(el => el === LEISURE_TYPE));
        const hasFormulaType = isNotUndefinedAndNotNull(activityTypes.find(el => el === FORMULA_TYPE));
        if (this.$route.params.id) {
          this.switchDisabled = true;
        } else {
          if (hasFormulaType) {
            this.switchDisabled = !(hasSportType || hasLessonType || hasLeisureType);
            if (isNotUndefinedAndNotNull(selectedTimeTableBlockPrice)) {
              if (selectedTimeTableBlockPrice.activityType === LESSON_TYPE || selectedTimeTableBlockPrice.activityType === FORMULA_TYPE) {
                this.bookingType = selectedTimeTableBlockPrice.activityType;
              } else {
                this.bookingType = DEFAULT_BOOKING_TYPE;
              }
            }
          } else if (hasLessonType && !hasSportType && !hasLeisureType) {
            this.switchDisabled = true;
            this.bookingType = LESSON_TYPE;
          } else if (hasLessonType && hasLeisureType && !hasSportType) {
            this.switchDisabled = false;
          } else if (!hasLessonType) {
            this.switchDisabled = true;
            this.bookingType = DEFAULT_BOOKING_TYPE;
          } else if (hasLessonType && hasSportType && !hasFormulaType) {
            this.switchDisabled = false;
            if (!(hasLessonType && this.bookingType === LESSON_TYPE)) {
              this.bookingType = DEFAULT_BOOKING_TYPE;
            } else {
              this.bookingType = LESSON_TYPE;
            }
          } else {
            this.switchDisabled = false;
            this.bookingType = DEFAULT_BOOKING_TYPE;
          }
        }
      }
    }
  },
  created() {
    this.loadPlaygrounds();
  },
  mounted() {
    this.$bus.$on('switch:booking-type', () => {
      if (!isNotUndefinedAndNotNull(this.$route.params.id)) {
        if (isNotUndefinedAndNotNull(this.playgoundSelected.activities) && this.playgoundSelected.activities.length > 1) {
          if (this.bookingType === DEFAULT_BOOKING_TYPE) {
            this.bookingType = LESSON_TYPE;
          } else {
            this.bookingType = DEFAULT_BOOKING_TYPE;
          }
        }
      }
    });
    this.$bus.$on('update:switch', (response = null) => {
      this.enableSwitch();
    });
    this.$bus.$on('on:playground-block-prices:loaded', (response = null) => {
      if (this.playgoundSelected.bookingType === 'unique') {
        if (!this.firstDeep) {
          this.firstDeep = true;
          this.enableSwitch();
        } else {
          // TODO SelectedActivity not supported by activity type
        }
      }
    });
  }
  ,
  beforeDestroy() {
    this.$bus.$off('on:playground-block-prices:loaded');
    this.$bus.$off('update:switch');
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/_b-card.scss";

.button-fixed {
  position: fixed;
  bottom: 0;
  width: 92%;
  background-color: white;
  z-index: 10;
}

.custom-mobile-button {
  width: 350px;
  padding: 13px 0;
  margin: 20px 10px;
  font-size: 18px;
}

.custom-card-padding .card-body {
  padding-left: 0;
  padding-right: 0;
}

.custom-card-padding {
  padding-left: 0;
  padding-right: 0;
}
</style>
