import ParticipantDiscount from "@/classes/doinsport/ParticipantDiscount";
import OptionDiscount from "@/classes/doinsport/OptionDiscount";
import BookingDiscount from "@/classes/doinsport/BookingDiscount";
import {isNotUndefinedAndNotNull} from "@/utils/classes";

export const AMOUNT_TYPE = 'value';

export const PERCENTAGE_TYPE = 'percentage';

export default class Discount {
  getDiscountModel(item) {
    let discount = null;

    switch (item.type) {
      case 'participant':
        discount =  new ParticipantDiscount();
        discount.initialPrice = item.toPay;
        break;
      case 'option':
        discount =  new OptionDiscount();
        discount.initialPrice = item.price;
        break;
      default:
        discount =  new BookingDiscount();
        discount.initialPrice = item.toPay;
        break;
    }

    discount.id = item.id;

    return discount;
  }

}
