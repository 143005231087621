import {hydrate} from "@/utils/form";

export default class Booking {
  activity = null;
  canceled = null;
  clients = null;
  club = null;
  comment = null;
  createdAt = null;
  endAt = null;
  id = null;
  name = null;
  playgrounds = [];
  startAt = null;
  updatedAt = null;
  actions = [];
  participant = [];
  payments = [];
  category = null;
  fromRecurrence = null;
  confirmed = false;
  creationOrigin = null;
  formattedNumber = null;
  match = null;
  participants = [];
  maxParticipantsCountLimit = null;
  minParticipantsCountLimit = null;
  paymentMethod = null;
  price = null;
  status = null;
  pricePerParticipant = null;
  restToPay = null;
  timetableBlockPrice = null;
  userClient = null;
  userClub = null;
  validatedAt = null;
  consideredAsPaidAt = null;
  recurrence = null;
  participantsCount = null;
  playgroundOptions = [];
  coaches = [];
  activityType = null;

  constructor(object = null) {
    if (object) {
      this.serialize(object);
    }
  }

  serialize(object) {
    hydrate(this, object);
  }
}
