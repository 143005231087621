<template>
  <div class="wrapped-switch">
    <div
      :class="isDefaultType ? 'active-switch no-switch' : noSwitch"
      @click="onSwitch('default')"
    >
      {{ $t('data.menu.bookings.meta.title')}}
    </div>
    <div
      :class="isFormulaType ? 'active-switch no-switch' : noSwitch"
      @click="onSwitch('formula')"
    >
      {{ $t('views.dashboard.content.activities.events.formula') }}
    </div>
    <div
      :class="isLessonType ? 'active-switch no-switch' : noSwitch"
      @click="onSwitch('lesson')"
    >
      {{ $t('views.dashboard.content.activities.events.course') }}
    </div>
  </div>
</template>
<script>
import {DEFAULT_BOOKING_TYPE, FORMULA_BOOKING_TYPE, LESSON_BOOKING_TYPE} from "@/classes/doinsport/BookingModel";

  export default {
    props: {
      bookingType: {
        type: String,
        default: DEFAULT_BOOKING_TYPE
      },
      disabledEdit: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      isDefaultType () {
        return this.bookingType === DEFAULT_BOOKING_TYPE;
      },
      isLessonType () {
        return this.bookingType === LESSON_BOOKING_TYPE;
      },
      isFormulaType () {
        return this.bookingType === FORMULA_BOOKING_TYPE;
      },
      noSwitch() {
        return this.disabledEdit ? 'no-switch' : 'no-switch pointer';
      }
    },
    methods: {
      onSwitch(type) {
        if (!this.disabledEdit) {
          this.$emit('on:switch', type);
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
.wrapped-switch {
  width: 360px;
  margin: auto;
  background: #F3F5F8;
  border-radius: 16px;
}

@media only screen and (max-height: 900px) {
  .wrapped-switch {
    width: 360px;
    text-align: center;
  }
}

.no-switch {
  display: inline-block;
  width: 118px;
  text-align: center;
  color: #BABABA;
  padding: 6px;
}

@media only screen and (max-height: 900px) {
  .no-switch {
    padding: 1px;
    width: 118px;
  }
}

.active-switch {
  background: #092772;
  border-radius: 16px;
  color: white;
  font-weight: 800;
}
</style>

