<template>
  <div class="pl-0 ml-0 d-flex">
    <div
      v-for="(day, i) of days"
      @click="selectDay(day)"
    >
      <d-avatar
        v-if="enabled"
        v-key="'Day' + i"
        :full-name="day.value"
        :color="day.selected ? '#435179' : '#F1F4F7'"
        :text-color="day.selected ? '#FFFFFF' : '#435179'"
        radius="0"
        text-font-size="20"
        size="40"
        class="mr-1 pointer"
      />
      <d-avatar
        v-else
        v-key="'Day' + i"
        :full-name="day.value"
        :color="'#f0f0f3'"
        :text-color="'#727d9b'"
        radius="0"
        text-font-size="20"
        size="40"
        class="mr-1"
      />
    </div>
  </div>
</template>
<script>
import {isNotUndefinedAndNotNull} from "@/utils/classes";
import {getWeekDayFromIndex} from "@/utils/form";

export default {
  data: () => ({
    days: [],
  }),
  props: {
    booking: {
      type: Object,
      default: () => {
      }
    },
    enabled: {
      type: Boolean,
      default: true
    },
    reload: {
      type: Boolean,
      default: false,
    },
    recurrence: {
      type: Object,
      default: this
    }
  },
  watch: {
    reload: function () {
      this.loadDays();
    }
  },
  computed: {
    weekdays ()  {
      return this.$moment.weekdays();
    }
  },
  methods: {
    selectDay(day) {
      if (this.enabled) {
        const findDay = this.days.find(el => parseInt(el.identifier) === parseInt(day.identifier));

        if (isNotUndefinedAndNotNull(day)) {
          findDay.selected = !findDay.selected;
        }

        this.updateDaysOfTheWeek();
        this.$emit('on:day-selected');
      }
    },
    updateDaysOfTheWeek() {
      this.recurrence.daysOfTheWeek = [];

      for (const day of this.days) {
        if (day.selected) {
          this.recurrence.daysOfTheWeek.push(parseInt(day.identifier));
        }
      }
    },
    loadDays() {
      const daysOfTheWeek = this.weekdays;
      this.days = [];

      for (const i in daysOfTheWeek) {
        if (parseInt(i) !== 0) {
          this.days.push({
            identifier: parseInt(i),
            value: this.$t(getWeekDayFromIndex(i))[0].toUpperCase(),
            selected: false,
          });
        }
      }
      this.days.push({
        identifier: 0,
        value: this.$t(getWeekDayFromIndex(0))[0].toUpperCase(),
        selected: false,
      });

      if (this.$moment(this.booking.startAt, this.$moment.ISO_8601, true).isValid()) {
        const findDayNumber = this.days.find(el => parseInt(el.identifier) === parseInt(this.$moment(this.booking.startAt).day()));

        if (isNotUndefinedAndNotNull(findDayNumber)) {
          this.selectDay(findDayNumber);
        }
      }
    },
    refreshWeekDayLabels () {
      for(const day of this.days) {
        day.value = this.$t(getWeekDayFromIndex(parseInt(day.identifier)))[0].toUpperCase();
      }
    },
  },
  created() {
    this.loadDays();
  },
  mounted() {
    this.$bus.$on('language-changed', this.refreshWeekDayLabels)
  },
  beforeDestroy() {
    try {
      this.$bus.off('language-changed');
    } catch (e) {}
  },
}
</script>
