import {hydrate} from "@/utils/form";
import {AMOUNT_TYPE} from "@/classes/doinsport/Discount";
import {toFloatFixed} from "@/utils/string";

export default class OptionDiscount {
  id = null;
  amount = null;
  bookingPlaygroundOption = null;
  amountType = AMOUNT_TYPE;
  initialPrice = null;

  constructor(object = null) {
    if (object) {
      this.serialize(object);
    }
  }

  serialize(object) {
    hydrate(this, object);
  }

  createDiscountCell(playgroundOption) {
    const discount = {
      id: playgroundOption.discount['@id'],
      amountType: playgroundOption.discount.amountType,
      bookingPlaygroundOption: playgroundOption.option['@id'],
      initialPrice: toFloatFixed(playgroundOption.price)
    }

    if (discount.amountType === AMOUNT_TYPE) {
      discount.amount = (parseFloat(playgroundOption.discount.amount) / 100).toFixed(2);
    } else {
      discount.amount = parseFloat(playgroundOption.discount.amount).toFixed(2);
      const discountPrice = 1 - (discount.amount / 100);
      discount.initialPrice = parseFloat(toFloatFixed(playgroundOption.price)) / discountPrice.toFixed(2);
    }

    return discount;
  }

  createOptionDiscountCell(response, findExistentPlaygroundOption) {
    return {
      id: response.data['@id'],
      amount: response.data.amountType === AMOUNT_TYPE ? toFloatFixed(response.data.amount) : response.data.amount,
      amountType: response.data.amountType,
      bookingPlaygroundOption: findExistentPlaygroundOption.option['@id'],
      initialPrice: toFloatFixed(findExistentPlaygroundOption.price),
    }
  }

  refineOption(object, iri) {
    delete object.id;

    delete object.initialPrice;
    if (object.amountType === AMOUNT_TYPE) {
      object.amount = parseInt(parseFloat(object.amount).toFixed(2) * 100);
    } else {
      object.amount = parseInt(parseFloat(object.amount).toFixed(2));
    }

    object.bookingPlaygroundOption = iri;

    return object;
  }
}
