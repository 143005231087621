import { hydrate } from "@/utils/form";

export default class BookingRecurrenceModel {
  durationCount = null;
  periodicityType = 'week';
  daysOfTheWeek = [];
  countLimit = null;
  toDate = null;

  constructor(object = null) {
    if (object) {
      this.serialize(object);
    }
  }

  serialize (object) {
    hydrate(this, object);
  }
}
