import {hydrate} from "@/utils/form";
import {AMOUNT_TYPE} from "@/classes/doinsport/Discount";
import {toFloatFixed} from "@/utils/string";
import {toPrice} from "@/utils/currency";

export default class ParticipantDiscount {
  id = null;
  amount = null;
  bookingParticipant = null;
  amountType = AMOUNT_TYPE;
  initialPrice = null;

  constructor(object = null) {
    if (object) {
      this.serialize(object);
    }
  }

  serialize(object) {
    hydrate(this, object);
  }

  createDiscountCell(participant) {
    const discount = {
      id: participant.discount['@id'],
      amountType: participant.discount.amountType,
      bookingParticipant: participant['@id'],
      initialPrice: toFloatFixed(participant.price)
    }

    if (discount.amountType === AMOUNT_TYPE) {
      discount.amount = (parseFloat(participant.discount.amount) / 100).toFixed(2);
    } else {
      discount.amount = parseFloat(participant.discount.amount).toFixed(2);
      const discountPrice = 1 - (discount.amount / 100);
      discount.initialPrice = parseFloat(toFloatFixed(participant.price)) / discountPrice.toFixed(2);

    }

    return discount;
  }

  createParticipantDiscountCell(response, findParticipantFromDiscount) {
    return {
      id: response.data['@id'],
      amount: response.data.amountType === AMOUNT_TYPE ? toFloatFixed(response.data.amount) : response.data.amount,
      amountType: response.data.amountType,
      bookingParticipant: findParticipantFromDiscount.iriParticipant,
      initialPrice: toPrice(findParticipantFromDiscount.toPay),
    }
  }

  refineParticipant(object, iri) {
    delete object.id;
    delete object.initialPrice;

    if (object.amountType === AMOUNT_TYPE) {
      object.amount = parseInt(parseFloat(object.amount).toFixed(2) * 100);
    } else {
      object.amount = parseInt(parseFloat(object.amount).toFixed(2));
    }

    object.bookingParticipant = iri;

    return object;
  }
}
