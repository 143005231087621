import { hydrate } from "@/utils/form";
import { AMOUNT_TYPE } from "@/classes/doinsport/Discount";
import {toFloatFixed} from "@/utils/string";
import {toPrice} from "@/utils/currency";

export default class BookingDiscount {
  id = null;
  booking = null;
  amount = null;
  amountType = AMOUNT_TYPE;
  initialPrice = null;

  constructor(object = null) {
    if (object) {
      this.serialize(object);
    }
  }

  serialize (object) {
    hydrate(this, object);
  }

  createDiscountCell(booking, bookingDiscount) {
    const discount = {
      id: bookingDiscount['@id'],
      amountType: bookingDiscount.amountType,
      booking: booking['@id'],
    }

    if (discount.amountType === AMOUNT_TYPE) {
      discount.initialPrice = toFloatFixed(bookingDiscount.amount + booking.price);
      discount.amount = toFloatFixed(bookingDiscount.amount);
    } else {
      discount.amount = parseFloat(bookingDiscount.amount).toFixed(2);
      const discountPrice = 1 - (discount.amount / 100);
      discount.initialPrice = parseFloat(toFloatFixed(booking.price)) / discountPrice.toFixed(2);
    }

    return discount;
  }

  createOptionDiscountCell(response, findParticipantFromDiscount) {
    return {
      id: response.data['@id'],
      amount: response.data.amountType === AMOUNT_TYPE ? toFloatFixed(response.data.amount) : response.data.amount,
      amountType: response.data.amountType,
      bookingParticipant: findParticipantFromDiscount.iriParticipant,
      initialPrice: toPrice(findParticipantFromDiscount.toPay),
    }
  }

  refineBooking (object, iri) {
    delete object.id;
    delete object.initialPrice;

    if (object.amountType === AMOUNT_TYPE) {
      object.amount = parseInt(parseFloat(object.amount).toFixed(2) * 100);
    } else {
      object.amount = parseInt(parseFloat(object.amount).toFixed(2));
    }

    object.booking = iri;

    return object;
  }
}
