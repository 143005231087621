<template>
  <b-modal
    id="DISCOUNT_MODAL_ID"
    hide-header
    hide-footer
    hide-header-close
    size="lg"
    header-class="my-second-class"
    body-bg-variant="gray-lighten"
  >
    <div
      v-if="discount"
      class="container-fluid"
    >
      <b-row class="pt-1 pb-1">
        <b-col align="left" class="popup-title">
          {{ $t('views.bookings.index.discount.add-discount') }}
        </b-col>
      </b-row>
      <div class="border-bottom-grey-dark mt-3 mb-3"/>
      <b-row>
        <b-col
          cols="2"
          align="left"
          class="payment-title d-flex align-items-center"
        >
          {{ $t('views.bookings.index.discount.discount') }}
        </b-col>
        <b-col class="btn-list" align="right">
          <d-button
            text="views.bookings.index.discount.percents"
            class="d-btn-sm btn font-text-title mr-2 d-btn-primary-outline-new"
            :class="discount.amountType === 'percentage' ? 'd-btn-primary-outline-new-selected' : 'd-btn-primary-outline-new'"
            @on:button-click="discount.amountType = 'percentage'"
          />
          <d-button
            text="views.bookings.index.discount.currency-discount"
            class="d-btn-sm btn font-text-title mr-2 d-btn-primary-outline-new"
            :class="discount.amountType === 'value' ? 'd-btn-primary-outline-new-selected' : 'd-btn-primary-outline-new'"
            @on:button-click="discount.amountType = 'value'"
          />
        </b-col>
      </b-row>
      <booking-discount-form
        :discount="discount"
      />
      <div class="border-bottom-grey-dark mt-3 mb-3"/>
      <b-row class="mt-3">
        <b-col align="right">
          <d-button
            text="general.actions.cancel"
            class="d-btn-sm btn d-btn-default font-text-title mr-3"
            @on:button-click="onCancel"
          />
          <d-button
            text="general.actions.save"
            class="d-btn-sm btn d-btn-primary-new font-text-title"
            @on:button-click="$emit('on:discount:save');hideModal()"
          />
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    display: {
      type: Boolean,
      default: false
    },
    hide: {
      type: Boolean,
      default: false
    },
    discount: {
      type: Object,
      default: null
    }
  },
  components: {
    BookingDiscountForm: () => import('@form/booking/create-or-update/BookingDiscountForm')
  },
  watch: {
    display: function () {
      this.showModal();
    },
    hide: function () {
      this.hideModal();
    }
  },
  methods: {
    onCancel () {
      this.hideModal();
    },
    showModal() {
      this.$bvModal.show('DISCOUNT_MODAL_ID');
    },
    hideModal() {
      this.$bvModal.hide('DISCOUNT_MODAL_ID');
    }
  },
}
</script>
<style lang="scss" scoped>
@import "@lazy/_modal.scss";
@import "@lazy/bookings/participant-payment-modal/_participant-payment-modal.scss";

@media (min-width: 992px) {
  /deep/ .modal-lg, .modal-xl {
    max-width: 600px;
  }
}
</style>
