import { _post, _get, _delete, _put, clubId} from "@api/doinsport/services/httpService";
import { URL } from "./index";

const DISCOUNT_URL = URL + '/discounts';

export const getDiscounts = (id) => _get(DISCOUNT_URL + '/' + id);

export const postDiscount = (discount) => _post(DISCOUNT_URL , discount);

export const deleteDiscount = (id) => _delete(DISCOUNT_URL + '/' + id);

export const putDiscount = (id, data) => _put(DISCOUNT_URL + '/' + id, data);
